import { motion } from 'framer-motion'
import { Children, isValidElement } from 'react'

export default function StaggeredAnimation ({ children, delay, ...props }) {
  const animContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay,
        staggerChildren: 0.2
      }
    }
  }

  const animItem = {
    hidden: { opacity: 0, marginTop: '1vw' },
    show: { opacity: 1, marginTop: 0 }
  }

  const updatedChildren = Children.map(children, (child, key) => {
    if (isValidElement(child)) {
      const MotionTag = motion[child.type]
      return (
        <MotionTag key={`motion-${key}`} variants={animItem} transition={{ delay: delay ? delay + key * 0.15 : key * 0.15 }} {...child.props}>{child.props.children}</MotionTag>
      )
    }
    return child
  })

  return (
    <motion.div variants={animContainer} initial='hidden' animate='show' {...props}>
      {updatedChildren}
    </motion.div>
  )
}
