import { motion } from 'framer-motion'
import Footer from './footer'

export default function Layout ({ children, footer = false, ...props }) {
  return (
    <div id='animationRoot'>
      <motion.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} {...props}>
        {children}
      </motion.main>
      {footer && (
        <Footer />
      )}
    </div>
  )
}
