import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Layout from '../shared/layout'
import '../../styles/pages/goals.scss'

export default function Goals ({ data }) {
  return (
    <Layout id={`${data.name.toLowerCase()}Goals`} className='goals-page' footer>
      <section>
        <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.25 } }} exit={{ opacity: 0 }}>{data.name}</motion.h1>
        <div className='row'>
          {data.goalsPage?.map((colData, i) => (
            <motion.div key={`goal-${i}`} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: (i * 0.1) + 0.5 } }} exit={{ opacity: 0 }}>
              <div className='tiles'>
                {colData?.goal.map((tile, ti) => (
                  <img key={`goal-${i}-tile-${ti}`} src={`/images/tiles/E-WEB-Goal-${tile}.png`} alt='' />
                ))}
              </div>
              <h2>{colData.heading}</h2>
              <p>{colData.content}</p>
              <ul>
                {colData.list.map((item, li) => (
                  <li key={`goal-${i}-listItem-${li}`}>{item}</li>
                ))}
              </ul>
              {colData.link && (
                colData.link.length > 1
                  ? (
                    <>
                      <Link to={colData.link.path}><b>See case studies:</b></Link>
                      {colData.link.map((link, i) => (
                        <Link key={`link-${link.text}-${i}`} to={link.path}>{link.text}</Link>
                      ))}
                    </>
                    )
                  : <Link to={colData.link[0].path}><b>See case study:</b><br />{colData.link[0].text}</Link>
              )}
            </motion.div>
          ))}
        </div>
      </section>
    </Layout>
  )
}
