import Layout from '../shared/layout'
import '../../styles/pages/intro.scss'
import { Link } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

export default function Intro ({ active, src }) {
  const video = useRef(null)
  const [videoReady, setVideoReady] = useState(false)

  const onVideoReady = () => {
    setVideoReady(true)
  }

  useEffect(() => {
    if (active && videoReady) {
      video.current.play()
    }
  }, [videoReady, active])

  useEffect(() => {
    if (video.current) {
      const videoElement = video.current
      return () => {
        videoElement.pause()
      }
    }
  }, [])

  return (
    <Layout id='intro'>
      <section>
        <Link to='/landing'>
          {active && videoReady && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25 }}>
              <img src='/images/play.png' alt='' />
            </motion.div>
          )}
          <video ref={video} muted loop onCanPlay={onVideoReady}>
            {src && <source src={src} type='video/mp4' />}
          </video>
        </Link>
      </section>
    </Layout>
  )
}
