import { Link } from 'react-router-dom'
import '../../styles/pages/landing.scss'
import { motion } from 'framer-motion'
import Layout from '../shared/layout'
import Data from '../../content'

export default function Landing () {
  return (
    <Layout id='landing'>
      <section className='grid'>
        <div>
          <img src='/images/home/1_1.jpg' alt='' />
        </div>
        <div>
          <img src='/images/home/1_2.jpg' alt='' />
        </div>
        <div>
          <img src='/images/home/1_3.jpg' alt='' />
        </div>
        <div>
          <motion.div className='link' initial={{ opacity: 0, paddingTop: '1vw' }} animate={{ opacity: 1, paddingTop: 0 }} transition={{ delay: 0.25 }}>
            <Link to={Data[0].path}>
              <h2>{Data[0].name}</h2>
            </Link>
          </motion.div>
          <img src='/images/home/2_1.jpg' alt='' />
        </div>
        <div>
          <motion.div className='link' initial={{ opacity: 0, paddingTop: '1vw' }} animate={{ opacity: 1, paddingTop: 0 }} transition={{ delay: 0.5 }}>
            <Link to={Data[1].path}>
              <h2>{Data[1].name}</h2>
            </Link>
          </motion.div>
          <img src='images/home/2_2.jpg' alt='' />
        </div>
        <div>
          <motion.div className='link' initial={{ opacity: 0, paddingTop: '1vw' }} animate={{ opacity: 1, paddingTop: 0 }} transition={{ delay: 0.75 }}>
            <Link to={Data[2].path}>
              <h2>{Data[2].name}</h2>
            </Link>
          </motion.div>
          <img src='/images/home/2_3.jpg' alt='' />
        </div>
        <div>
          <img src='/images/home/3_1.jpg' alt='' />
        </div>
        <div>
          <img src='/images/home/3_2.jpg' alt='' />
        </div>
        <div>
          <img src='/images/home/3_3.jpg' alt='' />
        </div>
      </section>
      <img className='logo' src='images/home/logo.png' alt='Ames Construction' />
      <motion.div className='btn-container' initial={{ opacity: 0, paddingTop: '1vw' }} animate={{ opacity: 1, paddingTop: 0 }} transition={{ delay: 1 }}>
        <img src='images/home/button.png' alt='Touch a Category to Explore' />
      </motion.div>
    </Layout>
  )
}
