import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import '../../styles/shared/footer.scss'
import Data from '../../content'

export default function Footer () {
  const location = useLocation()
  const navigate = useNavigate()

  const Arrow = ({ flip, ...props }) => {
    return (
      <svg {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2, transform: flip ? 'scaleX(-1)' : '' }} xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='100%' height='100%' viewBox='0 0 170 261' version='1.1' xmlSpace='preserve'>
        <path d='M169.922,260.193l-70.13,0l-99.792,-130.188l99.792,-130.005l70.13,0l-99.793,130.005l99.793,130.188Z' style={{ fill: '#fff', fillRule: 'nonzero' }} />
      </svg>
    )
  }

  const prev = () => {
    console.log('prev')
    const path = location.pathname.split('/')
    const parent = Data.find(d => d.path.replace('/', '') === path[1])
    if (parent?.caseStudies?.length) {
      const currentPage = path[2] && !isNaN(path[2]) ? Number(path[2]) : parent.caseStudies.length
      if (currentPage !== 0) {
        navigate(`${parent.path}/${currentPage - 1}`)
      } else {
        navigate(parent.path)
      }
    }
  }

  const next = () => {
    console.log('next')
    const path = location.pathname.split('/')
    const parent = Data.find(d => d.path.replace('/', '') === path[1])
    if (parent?.caseStudies?.length) {
      const currentPage = path[2] && !isNaN(path[2]) ? Number(path[2]) : -1
      if (currentPage !== parent.caseStudies.length - 1) {
        navigate(`${parent.path}/${currentPage + 1}`)
      } else {
        navigate(parent.path)
      }
    }
  }

  return (
    <footer>
      <ul>
        <li className='home'>
          <NavLink to='/landing'>
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='100%' height='100%' viewBox='0 0 291 262' version='1.1' xmlSpace='preserve' style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
              <path d='M290.717,145.65l-145.359,-145.65l-145.358,145.65l44.383,0l0,115.675l71.754,0l0,-88.029l58.442,-0l0,88.029l71.754,0l0,-115.675l44.384,0Z' style={{ fillRule: 'nonzero' }} />
            </svg>
          </NavLink>
        </li>
        {Data.map((data, i) => (
          <li key={`link-${i}`}>
            <NavLink to={data.path}>{data.name}</NavLink>
          </li>
        ))}
      </ul>
      <div className='footer-right'>
        <button onClick={prev}>
          <Arrow />
        </button>
        <span>Explore Our Case Studies</span>
        <button onClick={next}>
          <Arrow flip />
        </button>
      </div>
    </footer>
  )
}
